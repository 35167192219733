:root {
  --amplify-font-family: Source Sans Pro, sans-serif;

  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.875rem; /* Text */
  --amplify-text-sm: 1rem; /* Form Headers/Text */
  --amplify-text-md: 1.5rem; /* Title Headers */
  --amplify-text-lg: 2rem;
  --amplify-text-xl: 2.5rem;
  --amplify-text-xxl: 3rem;

  --amplify-primary-color: #bd8b13; /* Titles/Text = Cal Poly Gold */
  --amplify-primary-tint: #bd8b13;
  --amplify-primary-shade: #bd8b13;

  --amplify-secondary-color: #154734; /* Buttons/Links/Banners = Cal Poly Green */
  --amplify-secondary-tint: #154734;
  --amplify-secondary-shade: #154734;

  --amplify-tertiary-color: #bd8b13; /* Highlights = Cal Poly Gold */
  --amplify-tertiary-tint: #bd8b13;
  --amplify-tertiary-shade: #bd8b13;

  --amplify-grey: #54585a; /* Cal Poly Dark Gray */
  --amplify-light-grey: #8e9089; /* Cal Poly Light Gray */
}
